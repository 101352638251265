var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('div',[_c('div',{class:'portaltoptemplate'+_vm.$store.state.app.tenantshoppingmallconfig.portaltoptemplate,style:({background: _vm.brandArr.length?_vm.brandArr[_vm.backgroundcolorid].backgroundcolor:''})},[_c('div',{staticClass:"w-1200 h-410 m-t-10 flex m-b-10 m-a"},[_c('classify',{ref:"classify"}),_c('div',{staticClass:"w-800 "},[[_c('el-carousel',{attrs:{"interval":3000,"height":"410px"},on:{"change":_vm.carouselchange}},_vm._l((_vm.brandArr),function(item,index){return _c('el-carousel-item',{key:index},[_c('a',{staticClass:"p-l-10",attrs:{"href":item.linkurl}},[_c('img',{attrs:{"src":item.imageurl,"alt":""}})])])}),1)]],2),_c('classRight',{attrs:{"linetotal":_vm.linetotal}})],1)])]),_c('div',{staticClass:"pwidth m-t-10"},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"customindex",class:( _obj = {}, _obj[_vm.$style.previewBox] = _vm.isPreview, _obj )},_vm._l((_vm.trueComponentList),function(item){
var _obj;
return _c('div',{key:item.id,class:( _obj = {
					draggableSlot: item.$$slot,
					draggableItem: !item.$$slot
				}, _obj[("draggableSlot_" + (item.$$slot))] = item.$$slot, _obj ),attrs:{"slot":item.$$slot || 'default'},slot:item.$$slot || 'default'},[_c('ViewComponentWrap',{attrs:{"editor-item":item,"is-preview":_vm.isPreview}},[_c(item.componentViewName,{tag:"component",attrs:{"slot":"componentView","form-data":item.componentValue},slot:"componentView"})],1)],1)}),0)]),_c('customer',{ref:"customer",attrs:{"showcustomer":_vm.showpoup}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }