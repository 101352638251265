<template>
	<div class="w-200  classright relative" :class="$store.state.app.tenantshoppingmallconfig.portaltoptemplate==1?'h-410':'h-380 t-15'">
		<div class="tc absolute z-i-10 f-12 l-h-30 t-0 --color m-a l-0 r-0">
			<div class="iconfont b2b-ruzhuzhuangshi2 --color f-36 relative">
				<div class="relative z-i-10 c-f f-14 tc absolute z-i-10 t-0 l-0 r-0">已入驻<span class="f-14 bold"> {{linetotal}}</span> 个品种</div> 
			</div>
		</div>
		<div class="bg-f h100 relative p-t-70 tc t--3">
			<img src="@/assets/img/hear.png" alt="">
			<p class="c-6 m-t-15 m-b-30">Hi~欢迎来到商城中心</p>
			<div v-if="!$store.state.app.token" class="">
				<div class="w-70 h-36 l-h-35 tc --b-a-1 --color i-b rad-4  m-r-20 cursor">注册</div>
				<!-- <el-button size="medium" type="info" plain @click="()=>{$router.push({path:'/register'})}">注册</el-button> -->
				<el-button size="medium" type="primary" @click="()=>{$router.push({path:'/login'})}">登录</el-button>
			</div>
			<div v-else>
				<el-button size="medium" type="warning" plain @click="logout">退出</el-button>
			</div>
			
			<div class="p-0-15 h-116 absolute b-0 w100">
				<ul class="flex b-t-e5 tc p-t-25" v-if="$store.state.app.Operational">
					<li class="" @click="Jump(1)" >
						<!-- <p class="c-ffa f-18">{{getnumberArr.cartnumber||0}} </p> -->
						<img src="@/assets/erimg/index-3.png" alt="">
						<p class="f-12 m-t-8">{{$store.state.app.Operational.index.Purchasing}}</p>
					</li>
					<li class="" @click="Jump('1-1')">
						<!-- <p class="c-ffa f-18">{{getnumberArr.buyqty||0}} </p> -->
						<img src="@/assets/erimg/index-2.png" alt="">
						<p class="f-12 m-t-8">{{$store.state.app.Operational.index.applyhistory}}</p>
					</li>
						
					<li class="" @click="Jump('1-4')">
						<!-- <p class="c-ffa f-18">{{getnumberArr.favoritescount||0}}</p> -->
						<img src="@/assets/erimg/index-4.png" alt="">
						<p class="f-12 m-t-8">{{$store.state.app.Operational.index.Mycollection}}</p>
					</li>
				</ul>
				
			</div>
		</div>	
	</div>
</template>

<script>
	export default{
		props:['linetotal'],
		methods:{
			Jump(key){
				if(!this.$store.state.app.token) return this.$message({showClose: true,message: '请先登录',type: 'warning'});
				if(key==1)this.$router.push({path:'/cart',query:{key}})
				if(key!=1)this.$router.push({path:'/member',query:{key}})
			}
		}
	}
	
</script>

<style>
	.el-button--warning.is-plain{
		color: #FF8A00;
		background:unset;
		border:1px solid #FF8A00 !important;
		
	}
	.classright  .el-button .el-button--primary:hover,.classright .el-button--warning:hover{
		color: #fff !important;
	}
	.classright .el-button--warning:hover{
		background: #FF8A00;
	}
	
</style>