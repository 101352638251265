<template>
	
	<div v-if="loading">
		<div class="fix l-0 t-0 w100 z-i-10">
			<headertop />
			<headers/>
		</div>
		<div :class="$store.state.app.showguanggao?'h-184':'h-154'"></div>
		<!-- {{$store.state.app.headeractive}} -->
		<div class="header" v-if="$store.state.app.tenantshoppingmallconfig">
			<nav :style="{background:$store.state.app.tenantshoppingmallconfig.portaltoptemplate==3?'#ffffff !important':''}">
			    <ul >
			        <li  @click="()=>{$router.push({path:'./index'})}"  class="c-f bold h-40" 
					:class="'bg-0-01'">
			            <em><img src="@/assets/img/class.png" alt=""></em>所有商品分类
			        </li>
			        <li v-for="(item,index) in nav" :class="[$store.state.app.headeractive==item.key&&index!=0?'bold':'',$store.state.app.tenantshoppingmallconfig.portaltoptemplate==3&&$store.state.app.headeractive==item.key?'classicon':'']" :key="index" @click="btnNav(item.key)" :style="{color:$store.state.app.tenantshoppingmallconfig.portaltoptemplate==3?'#333 ':''}">
			            {{item.name}}
						 <p v-if="index !=0">
							<i class="w-60 h-4 i-b"  :class="$store.state.app.tenantshoppingmallconfig.portaltoptemplate==3?'--bg':'bg-f'"  v-if="$store.state.app.headeractive==item.key"></i>
						 </p>
			        </li>
				
			    </ul>
				
			</nav>
		</div>
	
		<customindex v-if="$store.state.app.headeractive==0"/>
		
		<!-- <index v-if="$store.state.app.headeractive==0"/> -->
		<!-- <introduce v-if="$store.state.app.headeractive==1||$store.state.app.headeractive==2"/> -->
		<newslist v-if="$store.state.app.headeractive==1"/>
		
		<contact v-if="$store.state.app.headeractive==2"/>
		<!-- <introduce v-if="$store.state.app.headeractive==3"/> -->
		
		<inspection v-if="$store.state.app.headeractive==3"/>
		<div class="h-20"></div>
		<footers @active="active"/>
	</div>
	
</template>

<script>
	import headertop from '../../components/headertop.vue';
	import headers from '../../components/header.vue';
	import footers from "../../components/footer.vue"
	// import index from "./index.vue"
	import customindex from "./customindex.vue"
	import introduce from "./introduce.vue"
	import inspection from "./inspection.vue"
	import newslist from "./indexnewlist.vue"
	import contact from "./contact.vue"
	export default{
		components:{customindex,introduce,inspection,footers,headertop,headers,newslist,contact},
		data(){
			return{
				loading:false,
				nav: [
					{name: "首页",key: 0}, 
					// { // name: "活动专区"}, 
					// { // name: "新品发现"}, 
					{name: "企业介绍",key: 1}, 
					{name: "联系我们",key: 2},
					{name: "批检报告",key: 3},
				],
				headeractive:0
			}
		},
		created() {
			this.getTenantid(()=>{
					 this.loading=true
					this.getRichtext()
				let gettenant=JSON.parse(sessionStorage.getItem('gettenant'));
				document.title=gettenant.tenantname
			})
		},
		
		watch:{
			$router(e){
				
			}
		},
		
		methods:{
			active(e){
				console.log('89898',e)
				this.headeractive = e;
			},
			
			btnNav(index) {
				this.headeractive = index;
				sessionStorage.setItem('headerNavs', index);
				this.$store.dispatch('PUBLIC',{type:'headeractive',res:index})
				this.$router.push({path:"/index"})
				let newslist=this.$store.state.app.newslist
				if(index==1)this.$router.push({path:'/index',query:{showdetail:true,detailid:newslist.QYJS.length?newslist.QYJS[0].id:''}})
				// if(index==2)this.$router.push({path:'/contact',query:{showdetail:true,detailid:newslist.LXWM.length?newslist.LXWM[0].id:''}})
				this.Backtop()
			},
		}
		
	}
		
</script>

<style>

</style>
