<template>
	
	<div class="w-200" :class="$store.state.app.tenantshoppingmallconfig.portaltoptemplate!=2?'bg-f':'bg-0-04 c-f'" >
		<ul class="tl relative">
	
			<li class="l-h-40 Dashed-t p-l-20  cursor flex start" :class="'fuclass'+$store.state.app.tenantshoppingmallconfig.portaltoptemplate" v-for="(item,index) in getclassifyArr" :key="index">
				<i class="iconfont f-24 m-r-10 "  :class="[$store.state.app.tenantshoppingmallconfig.portaltoptemplate==2?'':'classicon',item.icon]"></i>
				<span @click.stop="toGoodslist(item,0)">{{item.categoryname}}</span>
				<div class="cursor ziclass flex p-20 warp c-3" v-if="item.subCategory.length">
					<div class="rad-5 p-0-10 i-b name" style="" v-for="(items,ind) in item.subCategory" :key="ind" @click.stop="toGoodslist(items,1)">{{items.categoryname}}</div>
				</div>
			</li>
		</ul>
	</div>
</template>

<script>
	export default {
		data() {
			return{
				getclassifyArr:[]
			}
		},
		activated() {
			this.getclassify()
		},
		methods:{
			
			//获取导航分类列表 POST /b2bgoods/category
			getclassify: function getclassify() {
				var self = this;
				var Url = '/b2bgoods/category';
				var data = {isloadsub: true};
				this.requset(Url, data).then(function(data) {
					var data = data.data;
					data.map((res,i)=>{
						switch (i){
							case 0:res['icon']='b2b-xiyao';break;
							case 1:res['icon']='b2b-zhongchengyao';break;
							case 2:res['icon']='b2b-yiliaoqixie';break;
							case 3:res['icon']='b2b-shipin-baojianpin';break;
							case 4:res['icon']='b2b-jishengyongpin';break;
							case 5:res['icon']='b2b-huazhuangpin';break;
							case 6:res['icon']='b2b-xiaoduyongpin';break;
							case 7:res['icon']='b2b-qita';break;
							case 8:res['icon']='b2b-qita';break;
							case 9:res['icon']='b2b-qita';break;
							
						}
					})
					self.getclassifyArr = data.splice(0, 10);
					
					console.log('分类',self.getclassifyArr);
				});
			
			},
			
			// 选择分类到商品列表
			toGoodslist: function toGoodslist(item, child) {
				
				// window.location.href = "/view/goods/goodslist.html?child=" + child + "&goodsid=" + item.categoryno +
				// 	"&parentid=" + item.parentid + "&categoryname=" + item.categoryname + "&id=" + item.id;
				// if(!this.$store.state.app.token) return this.$message({showClose: true,message: '请先登录',type: 'warning'});
				
				// console.log('分类',this.islogin());
				if(!this.islogin())return
				this.$router.push({path:'/goodslist',query:{ child,goodsid:item.categoryno,parentid:item.parentid,categoryname:item.categoryname,id:item.id}})
					
			},
		}
	}
</script>

<style>
	.ziclass{
		display: none;
		width: 790px;
		height: 410px;
		position: absolute;
		left: 200px;
		top: 0px;
		z-index: 1000;
		background: #F0F0F0;
		line-height: 30px;
		/* border-radius: 10px; */
		
	}

	.fuclass1:hover{background: #F0F0F0;border: none;}
	.fuclass2:hover{
		color:var(--selectedColor) !important;border: none;
		background: #fff;
	}
	.fuclass3:hover{background: #F0F0F0;border: none;}
	
	.fuclass1:hover +.fuclass1{border: none}
	.fuclass2:hover +.fuclass2{border: none}
	.fuclass3:hover +.fuclass3{border: none}
	
	.fuclass1:hover .ziclass,.fuclass2:hover .ziclass,.fuclass3:hover .ziclass{display: block;}
	
	
</style>